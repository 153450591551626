.modal-select-brief-type-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 5px;
  padding: 5%;
  box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.05);
  font-family: 'Poppins', sans-serif;
}

.modal-select-brief-type-dialog {
  max-width: 600px;
  border: 0 !important;
  box-shadow: none !important;
}

.modal-select-brief-type-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-select-brief-type-body svg {
  /* height: 242px; */
}

.modal-select-brief-type-body-content {
  /* display: flex;
  justify-content: space-around;
  margin-top: 5%; */
}

.modal-select-brief-type-body-content-item span {
  margin-right: 20px;
}

.modal-select-brief-type-body-content-item .custom-button-wrapper {
  width: 100%;
}

.modal-select-brief-type-body-content-item button {
  box-shadow: none;
}

.modal-select-brief-type-body-content-item button:hover {
  box-shadow: none;
}

.modal-select-brief-type-body-content-item button:focus {
  box-shadow: none !important;
  background: none;
}

.modal-select-brief-type-body-content-item {
  position: relative;
  /* flex-direction: column; */
  /* max-width: 138px; */
  padding: 0 30px;
  margin: 0 20px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110.52%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-transform: none;
    text-transform: initial;
    display: flex;
    text-align: center;
    border-radius: 40px;
    background: none;
    border: 1px solid #4DA5EC;
    color: #4DA5EC;
    align-items: center;
    width: 320px;
}

.modal-select-brief-type-body-content-item:hover {
  background: #4DA5EC;
}

.modal-select-brief-type-body-content-item:hover button {
  color: #fff;
}

.modal-select-brief-type-body-content-item:hover svg path {
  fill: #fff;
}

.line {
  content: '';
  display: block;
  position: absolute;
  height: 68%;
  width: 2px;
  background-color: #ccd0d4;
  border-radius: 98%;
  box-shadow: 0px 0px 100px -15px #fff inset;
}

.modal-select-brief-type-header {
  display: flex;
  flex-direction: column;
  margin-top: -16px;
}

.back-wrapper {
  margin-right: 1rem;
  color: grey;
  position: absolute;
  left: 0;
}

.back-wrapper i {
  margin-right: 1rem;
  cursor: pointer;
}

.modal-select-brief-type-header-cross-wrapper {
  position: absolute;
  cursor: pointer;
  top: 14px;
  right: 14px;
}

.modal-select-brief-type-header-cross {
  border-radius: 50%;
  padding: 7px;
  background: none;
  transition: box-shadow .15s ease-in-out;
}

.modal-select-brief-type-header-cross button {
  box-shadow: none;
}

.modal-select-brief-type-cross:hover {
  box-shadow: 0 0 0 62.5rem rgba(0,0,0,.075) inset;
}

.cross-icon {
  height: 12px;
  width: 12px;
  fill: #fff;
}

.modal-select-brief-type-header-title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 22px;
  text-align: center;
  /* position: absolute; */
  width: 100%;
} 

.modal-select-brief-type-header-text {
  color: #052947;
  font-size: 15px;
}

.modal-select-brief-type-types-icon {
  /* height: 70px; */
  /* width: 70px; */
  /* margin-left: 3px; */
}

.modal-select-brief-type-types-icon-wrapper {
  /* background-color: #D4DCE6; */
  /* padding: 32px; */
  /* border-radius: 50%; */
  width: 24px;
  height: auto;
}

.modal-select-brief-type-types-icon-wrapper svg path {
fill: #4DA5EC;
}
.modal-select-brief-type-body-content-button {
  color: #4DA5EC;
  /* background-color: #fff; */
  /* border: 1px solid #ccd0d4; */
  background: none;
  font-size: 14px;
  /* margin-top: 21px; */
  padding: 10px;
  text-transform: none;
  border-radius: 3px;
}

.modal-select-brief-type-body-content-button:hover {
  box-shadow: none;
}

.modal-select-brief-type-body-content-info {
  text-align: center;
  margin-top: 15px;
  color: #363c42;
  font-size: 13px;
}

.modal-select-brief-type-button-is-active {
  /* background-color: #2f48b6; */
  /* color: #fff; */
  /* border-color: transparent; */
}

.modal-select-brief-type-body-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin: 24px 0; 
}

.modal-select-brief-type-body-subinfo {
  margin-top: 20px;
  font-size: 13px;
line-height: 151.5%;
text-align: center;
color: #4A4A4A;
}


.modal-select-brief-type-back-button {
  /* position: absolute; */
  width: 90px;
  /* right: 14px;
  bottom: 14px; */
}

#createBriefModal {
  z-index: 99999;
}