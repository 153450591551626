.navbar {
  box-shadow: none;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-default .navbar-nav.navbar-right > li > a {
  padding: 12px 12px;
}

.navigation-search {
  display: flex;
  margin-left: 20px;
  display: none !important;
}

.navigation-search .search-input {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: transparent;
  border: solid 1px #ccd0d4;
}

.navbar-nav > .dropdown-user > a > img + span {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 110.52%;
  letter-spacing: 0.01em;
  color: #052947;
}

.navbar-default .navbar-nav > li > .dropdown-menu {
  background: #fefefe;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  top: 50px;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.01em;
}

.navbar-default .navbar-nav > li > .dropdown-menu > li > a > i {
  color: #4da6ec;
}

.dropdown-user .dropdown-menu > li > a {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px;
  align-items: center;
}

.dropdown-user > .dropdown-menu {
  width: 267px;
  padding: 2px 0;
  overflow: hidden;
}

.dropdown-user > .dropdown-menu .divider {
  margin: 0px 25px;
}

.dropdown-user .dropdown-menu > li:not(.divider) {
  padding: 0 !important;
}

.dropdown-user .dropdown-menu > li:not(.divider) > a {
  padding: 18px 25px !important;
  font-style: normal;
  font-size: 14px;
  color: #343a40;
  letter-spacing: 0.01em;
}

.dropdown-user .dropdown-menu > li > a svg {
  margin-right: 10px;
  width: 20px;
}

.dropdown-user .dropdown-menu > li:first-child {
  margin-top: -2px;
}

.dropdown-user .dropdown-menu > li:last-child {
  font-size: 14px;
  color: #343a40;
  letter-spacing: 0.01em;
  font-weight: 500;
  font-family: 'SF Pro Display';
  background: #f7f7fa;
  margin-bottom: -2px;
}

.dropdown-user .dropdown-menu > li:last-child > a {
  font-weight: 500;
}

.dropdown-notification {
  margin-left: -10px;
}

.calendar-icon {
  height: 22px;
  width: 22px;
}

.navbar-right > li {
  height: 100%;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.app-content {
  position: relative;
  margin: 33px 29px !important;
  min-height: inherit;
}

@media (max-width: 428px) {
  .app-content {
    padding: 10px;
    margin: 10px 5px !important;
  }
}

.app-content-footer {
  display: flex;
  justify-content: center;
  bottom: 32px;
  left: 0;
  width: 100%;
}

.media-delete-notification {
  background: none !important;
  border: none !important;
  height: 27px;
  width: 27px;
}

.icon-cross {
  color: #999999 !important;
  font-size: 12px !important;
}

.icon-add-navigation {
  padding: 15px 16px;
  cursor: pointer;
}

.content-wrapper {
  box-shadow: none !important;
  min-height: inherit;
}
.navigation-item-icon-wrapper {
  display: block !important;
  margin: 0 10px 0 0 !important;
}

.navigation-item-icon {
  height: 12px;
  width: 12px;
  margin-top: -2px;
}

.navigation-item-text {
  font-size: 14px;
  font-weight: 400;
}

.navigation-item-active-route > span {
  font-weight: 500;
}

.navigation-item-active-route {
  border-left: 2px solid #fff;
  background: linear-gradient(to right, #4f61ae 0%, transparent 100%);
}

.navigation-item {
  padding: 14px 19px !important;
}

@media (min-width: 769px) {
  .app-navbar-wrapper {
    margin-left: 200px;
  }
}

.sidebar-xs .app-navbar-wrapper {
  margin-left: 56px;
}

.sidebar-logo {
  position: fixed;
  top: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 769px) {
  .sidebar-xs .sidebar-main .sidebar-logo {
    width: 56px;
    margin-left: 0;
  }

  .sidebar-xs .sidebar-main .sidebar-logo .sidebar-brand > img {
    display: none;
  }
}

.navbar-icon-menu {
  margin-top: 5px;
}

/* .navbar {
  min-height: 70px;
} */

.navbar-collapse {
  margin-top: 5px;
}

.navbar-top {
  padding-top: 70px;
}

.nav-button-analytics {
  display: flex;
}

.nav-button-analytics-beta {
  font-size: 11px;
  margin-top: -2px;
  margin-left: 4px;
}

@media (max-width: 769px) {
  .navbar-default {
    padding-left: 0 !important;
  }
  .navbar-right {
    display: flex;
    justify-content: center;
  }
  .dropdown-user {
    position: absolute !important;
    top: 0;
    left: 10px;
    background: #ffff;
    height: fit-content !important;
    z-index: 1000;
  }
  .dropdown-notification .dropdown-menu.dropdown-content {
    position: fixed;
    width: -webkit-fill-available;
    background: #ffffff !important;
    height: auto;
    transform: translate(0%, 25%);
  }
  .navbar-top {
    padding-top: 20px;
  }
}

.dropdown-user-messages-icon {
  margin-top: -5px;
}

.justify-end {
  display: flex;
  justify-content: flex-end !important;
}

.sidebar-xs .sidebar-main .navigation > li > div > span {
  /* display: none; */
}

.sidebar-xs .sidebar-main .navigation li .navigation-item-sub-items-item {
  display: none;
}

.sidebar-xs .navigation-item-text {
  display: none;
}

.notification-show-more-button {
  margin-bottom: 10px;
}

.header-post-brief .dashboard-briefs-post-job-button {
  background: none !important;
  box-shadow: none;
  width: auto;
}

.header-post-brief .dashboard-briefs-post-job-button-wrapper:hover {
  background: none !important;
}

.header-post-brief .dashboard-briefs-post-job-button-wrapper button:hover {
  background: none !important;
  box-shadow: none;
}

.header-post-brief .dashboard-briefs-post-job-button span {
  display: none;
  
}

.header-post-brief .dashboard-briefs-post-job-button svg {
  right: auto;
  margin-left: 0;
}

.header-post-brief .dashboard-briefs-post-job-button svg path {
  fill: #9c9fa6;
}