.legal-modal-dialog {
  background: #fff;
  border-radius: 10px;
  padding: 90px 64px 40px 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: SF Pro Display;
  width: 766px;
  min-height: 440px;
  transform: none !important;
  position: relative;
  left: initial;
  top: initial;
}

.legal-modal-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.legal-modal-header-title {
  font-weight: bold;
  font-size: 25px;
  color: #052947;
}

.legal-modal-header-info {
  font-weight: 600;
  font-size: 13px;
  color: #9c9fa6;
}

.legal-modal-accept-terms-info ul {
  margin: 15px 0;
}

.legal-modal-accept-terms-info ul li:not(.legal-modal-accept-terms-info ul li:last-child) {
  margin-bottom: 10px;
}

.legal-modal-cross-wrapper {
  position: absolute;
  right: 33px;
  top: 27px;
}

.legal-modal-cross {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #d9dadd;
  padding: 0;
}

.legal-modal-cross-icon path {
  fill: #fff;
}

.legal-modal-footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 30px;
}

.legal-modal-footer-button-wrapper:not(:last-child) {
  margin-right: 10px;
}

.legal-modal-footer-button {
  width: 130px;
  height: 33px;
  border-radius: 33px;
  border: 1px solid #c4c4c4;
  background: transparent;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
  color: #6f6f6f;
}

.legal-modal-footer-button-continue {
  background: #2f48b6;
  border-color: #2f48b6;
  color: #fff;
}

.legal-modal-decline-content {
  flex: 1;
  display: flex;
}

.legal-modal-decline-content-reason-textarea {
  resize: none;
  flex: 1;
  border: 1px solid #31abf4;
  border-radius: 6px;
  padding: 15px 22px;
}

.legal-modal-accept-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.legal-modal-accept-terms {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  color: #818181;
}

.legal-modal-accept-terms .checker {
  position: relative;
  height: 17px;
  width: 17px;
}

.legal-modal-accept-terms .checkbox {
  margin-right: 10px;
  margin-top: -3px;
}

.legal-modal-accept-terms-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 20px;
}

.legal-modal-accept-terms-info a {
  color: #31abf4;
  text-decoration: underline;
}

.legal-modal-accept-terms-info-learn-link {
  color: #213FC4 !important;
}

@media(max-width: 400px) {

  .legal-modal-dialog {
    padding-left: 34px;
    padding-right: 34px;
  }
  
  .legal-modal-footer {
    justify-content: center;
  }
  .legal-modal-footer-button-wrapper:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .legal-modal-dialog {
    width: auto !important;
    margin: 20px 20px;
  }
}