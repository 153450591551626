.spiner-center-absolute {
  position: absolute !important;
  left: 48% !important;
  top: 36% !important;  
}

.spiner-center-relative {
  position: relative !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.spiner-tr-wrapper {
  height: 100px;
}
