@media screen and (max-height: 760px) {
  .manage-user-modal-dialog{
    margin-top: 5%;
  }
}

@media screen and (max-height: 700px) {
  .manage-user-modal-dialog{
    margin-top: 10%;
  }
}

@media screen and (max-height: 540px) {
  .manage-user-modal-dialog{
    margin-top: 15%;
  }
}

@media screen and (max-height: 380px) {
  .manage-user-modal-dialog{
    margin-top: 20%;
  }
}

@media screen and (max-height: 220px) {
  .manage-user-modal-dialog{
    margin-top: 25%;
  }
}


.manage-user-modal-dialog {
  font-family: SF Pro Display;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  width: 766px !important;
  height: 746px !important;
  padding: 86px 69px 43px 69px;
  background: #ffffff;
  border-radius: 10px;
  transform: translate(-50%, -50%) !important;


  
}

.manage-users-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.manage-user-modal-cross-icon {
  position: absolute;
  right: 33px;
  top: 27px;
  cursor: pointer;
}

.manage-users-modal-header {
  display: flex;
  flex-direction: column;
}

.manage-users-modal-header-title {
  font-weight: bold;
  font-size: 25px;
  line-height: 28px;
  color: #052947;
}

.manage-users-modal-header-info {
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  color: #9c9fa6;
}

.manage-users-modal-header-search-wrapper {
  margin-top: 20px;
  margin-bottom: 44px;
}

.manage-users-modal-header-search-input-wrapper {
  width: 100%;
}

.manage-users-modal-header-search-input {
  border: 1px solid #31abf4;
  border-radius: 6px;
  width: 100%;
  height: 50px;
}

.manage-users-modal-header-search-input-icon {
  right: 36px;
  height: 15px;
  width: 15px;
}

.manage-user-modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.manage-user-modal-footer-buttons {
  display: flex;
}

.manage-user-modal-footer-button {
  width: 130px;
  height: 33px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 33px;
  color: #6f6f6f;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
}

.manage-user-modal-footer-button-wrapper:not(:last-child) {
  margin-right: 10px;
}

.manage-user-modal-footer-button-update {
  background: #2f48b6;
  border-color: #2f48b6;
  color: #ffffff;
}

.manage-users-modal-mediacom {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.manage-users-modal-mediacom-text {
  font-weight: 500;
  font-size: 20px;
  color: #31abf4;
  margin-left: 14px;
}

.manage-users-modal-users {
  display: flex;
  flex: 1 auto;
  flex-direction: column;
  overflow: auto;
  background: #fbfbfb;
  border: 1px solid #c2c2c2;
  border-radius: 6px;
  padding: 0 24px;
  height: 346px;
}

.manage-users-modal-users-user-info {
  display: flex;
  align-items: center;
  padding: 18px 0;
}

.manage-users-modal-users-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manage-users-modal-users-user:not(:last-child) {
  border-bottom: 1px solid #cbcdd0;
}

.manage-users-modal-users-user-avatar {
  width: 51px;
  height: 51px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 11px;
}

.manage-users-modal-users-user-name {
  font-weight: 600;
  font-size: 16px;
  color: #494949;
}

.manage-users-modal-users-actions {
  display: flex;
}

.manage-users-modal-users-actions .select-container {
  margin-right: 30px;
}

.manage-users-modal-users-actions .select2-selection__rendered {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #494949;
  margin-right: 10px;
}

.manage-users-modal-users-actions .select2-selection--single:not([class*=bg-]):not([class*=border-]) {
  border: none;
  padding-top: 0;
}

.manage-users-modal-users-actions .select-arrow-wrapper {
  top: -5px;
}

.manage-users-modal-users-actions .select-arrow-wrapper .select-arrow path {
  fill: #052947 !important;
}

.manage-users-modal-users-actions > .radio {
  margin-bottom: 0 !important;
}
.manage-users-modal-users-actions > .radio,
.manage-users-modal-users-actions > .radio > .choice,
.manage-users-modal-users-actions > .radio > .choice span {
  width: 23px;
  height: 23px;
}

.manage-users-modal-users-actions > .radio > .choice {
  top: 0;
}

.manage-users-modal-users-actions > .radio > .choice span {
  border: 1px solid #9E9E9E;
}

.manage-users-modal-users-actions > .radio > .choice span.checked {
  border: 7px solid #2F48B6;
}

.manage-users-modal-users-actions > .radio > .choice span.checked:after {
  display: none;
}

.manage-users-modal-users::-webkit-scrollbar { 
  width: 14px; 
}

.manage-users-modal-users::-webkit-scrollbar-thumb { 
  border: 4px solid #fbfbfb; 
  background: #ccd0d4; 
  border-radius: 33px; 
  transform: matrix(0, -1, -1, 0, 0, 0); 
}

