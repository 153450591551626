.navigation-item-title {
    display: flex;
    align-items: center;
}

.navigation-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.navigation-item-sub-items {
    margin-top: 8px;
    width: 100%;
}

.navigation-item-sub-items-item {
  padding: 8px 0 8px 22px;
}