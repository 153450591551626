.post-brief-modal .modal-content {
    height: auto;
}

.post-brief-modal .modal-dialog {
    width: 56% !important;
}

.post-brief-modal-description {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 151.5%;
    text-align: center;
    color: #4A4A4A;
    margin-top: 20px;
}

.post-brief-modal-btn-container {
    margin-top: 20px;
    justify-content: center;
    display: grid;
    align-items: center;
}

.post-brief-modal-btn-container .btn {
    margin: 0 20px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110.52%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-transform: initial;
    display: flex;
    text-align: center;
    border-radius: 40px;
    background: none;
    align-items: center;
    width: 320px;
}

.post-brief-modal-btn-container .btn:hover {
    background: #4DA5EC !important;
    color: #fff !important;
}

.post-brief-modal-btn-container .btn:hover svg path {
    fill: #fff;
}

.post-brief-modal .modal-header .close {
    right: 0 !important;
    top: 0 !important;
}

.post-brief-modal-btn-container span {
    display: flex;
    justify-content: center;
    width: 100%;
}

.post-brief-modal-btn-container .btn:nth-child(1) {
    /* background: #4DA5EC; */
    background: none;
    border: 1px solid #4DA5EC;
    color: #4DA5EC;
}

.post-brief-modal-btn-container .btn:nth-child(2) {
    background: none;
    border: 1px solid #4DA5EC;
    color: #4DA5EC;
}

.post-brief-modal-btn-container .btn:nth-child(3) {
    background: none;
    border: 1px solid #4DA5EC;
    color: #4DA5EC;
}

.post-brief-modal-btn-container .btn svg {
    margin-right: 20px;
}

.post-brief-modal-btn-container .btn svg path {
    fill: #4DA5EC;
}

.post-brief-modal-icon-container {
    display: flex;
    justify-content: center;
    margin: 40px auto;
}


.post-brief-modal-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    color: #052947;
    margin-bottom: 22px;
    text-align: center;
    margin-top: -24px;
}

.post-brief-modal-sub-heading {
    color: #052947;
    font-size: 15px;
}

.post-brief-modal .ui-dropzone-divider {
    height: 200px !important;
}

.post-brief-modal-content {
    /* width: 200px; */
    width: 40%;
}
