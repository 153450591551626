.search-wrapper, .search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  color: #163753;
}

.search-input-label {
  margin-right: 5px;
}

.search-input {
  color: #333333;
  border: none;
  padding: 3px 0 3px 25px;
  background-color: transparent;
  border-bottom: 1px solid #B1B9C0;
  border-radius: 0;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.01em;
}

.search-input::placeholder {
  color: #AEAEAE;
}

.search-input-icon {
  position: absolute;
  cursor: pointer;
}