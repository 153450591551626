.notifications-list-item-removing-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0.5;
  background: #fff;
}

.notification-body-block-size {
  max-width: 200px;
}
