@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&subset=cyrillic,cyrillic-ext,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

body {
  background: #e7e9f0 !important;
}

.page-container {
  min-height: 80vh;
}

.logo {
  height: 30px !important;
  margin-top: -4px !important;
}

.navbar-brand {
  padding: 5px 20px;
  height: 50px;
}

.navbar-brand > img {
  margin-top: 2px;
  height: 38px;
}

.text-center {
  text-align: center;
}

.logo-size {
  height: 30px !important;
  width: 90px !important;
}

.p-0 {
  padding: 0 !important;
}

.left-sidebar-color {
  background-color: #1b3296;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}
.media-body a:not(#hidden-pitch) {
  text-transform: none !important;
  color: #1e88e5 !important;
}

/* Slider styles start */
.rc-slider-track {
  background-image: linear-gradient(to right, #90caf9, #047edf) !important;
  height: 3px !important;
}

.rc-slider-handle {
  border: solid 1px #50a7ed !important;
  background-color: #ffffff !important;
}

.rc-slider-handle:active {
  box-shadow: 0 0 3px #50a7ed;
}

.rc-slider-rail {
  background-color: #d9d9d9 !important;
  height: 3px !important;
}

.slider__wrapper {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 4px !important;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.13;
  text-align: left;
  color: #052947;
}

.rc-slider-disabled {
  background-color: inherit !important;
}

.downloadLink {
  display: none;
}

.icon-margin {
  margin-right: 10px !important;
}

.list-items-margin {
  margin: 8px 0;
}
/* Slider styles end */

.d-none {
  display: none;
}

.box {
  display: none;
}

/* Datepicker class upgrade */
.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

/* Hide tooltip of charts */
.d3-tip {
  display: none !important;
}

.background-inherit {
  background: inherit !important;
}

.tiny-show-mode {
  overflow: auto !important;
  min-height: 300px !important;
}

.width-100 {
  width: 100px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.container {
  font-family: 'Roboto', Helvetica Neue, Helvetica, Arial, sans-serif;
}

.editor {
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px 0;
  -moz-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px 0;
  -webkit-box-shadow: rgba(0, 0, 0, 0.4) 0 1px 3px 0;
  margin-bottom: 30px;
  padding: 10px;
  width: 700px;
  height: 455px;
}
h1,
h3 {
  color: #7f7f7f;
  text-shadow: 0 1px 0 white;
}
.control {
  font: 12px Arial;
  padding: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.control button {
  margin-right: 5px;
}
#content {
  color: #333333;
  font: 14px;
}
#content,
#tinymce-wrapper {
  height: 300px;
}
#text-wrapper {
  width: 620px;
  background: #cfcfcf;
  min-height: 300px;
  max-height: 300px;
  overflow: auto;
  padding: 40px;
  border: 1px solid #bfbfbf;
  font-size: 15px;
  line-height: 24px;
}
#textbody {
  margin: 0 auto;
  border: solid 2px #bfbfbf;
  background: white;
  padding: 15px 30px 30px;
  outline: none;
}
a.mceButton16 img.mceIcon {
  width: 16px;
  height: 16px;
  padding: 2px;
}

.CT-hide .del,
.CT-hide .del {
  display: none;
}

.CT-hide .ins,
.CT-hide .ins {
  color: #333333;
  background: none !important;
  border: none !important;
  text-decoration: none;
}

.del {
  text-decoration: line-through;
}
.ins {
  text-decoration: underline;
}

.cts-1 {
  color: green;
}
.del.cts-1 img {
  border-color: green;
}
.ins.cts-1 img {
  background-color: green;
}
.cts-2 {
  color: #c02000;
}
.del.cts-2 img {
  border-color: #c02000;
}
.ins.cts-2 img {
  background-color: #c02000;
}
.cts-3 {
  color: #004090;
}
.del.cts-3 img {
  border-color: #004090;
}
.ins.cts-3 img {
  background-color: #004090;
}
.cts-4 {
  color: #f06000;
}
.del.cts-4 img {
  border-color: #f06000;
}
.ins.cts-4 img {
  background-color: #f06000;
}
.cts-5 {
  color: purple;
}
.del.cts-5 img {
  border-color: purple;
}
.ins.cts-5 img {
  background-color: purple;
}
.cts-6 {
  color: #801080;
}
.del.cts-6 img {
  border-color: #801080;
}
.ins.cts-6 img {
  background-color: #801080;
}
.cts-7 {
  color: #1080b0;
}
.del.cts-7 img {
  border-color: #1080b0;
}
.ins.cts-7 img {
  background-color: #1080b0;
}

.ice-avoid {
  color: red;
}

.del img {
  border-top-style: dotted;
  border-bottom-style: dotted;
  border-width: 4px;
}

.ins img {
  padding: 3px;
}

.ins .del img {
  padding: 0px 3px;
}

.dropzone {
  border-radius: 23px;
}

.form-control[readonly] {
  border-bottom-style: none !important;
  height: 38px !important;
  resize: none !important;
}

.text-block {
  white-space: pre-wrap;
  border-bottom-color: #fff;
  display: inline-block !important;
  word-break: normal;
}

.border_bottom_none {
  border-bottom-color: white !important;
}

.button_length {
  min-width: 120px;
  margin: 0px 10px;
}
.center-flex {
  display: flex;
  align-items: center;
}

.li_item_padding {
  padding: 8px 16px !important;
}

.d-flex_center {
  display: flex;
  justify-content: center;
}

.tab-content > h2 {
  margin-top: 0;
}

.d-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.d-flex-nowrap {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: nowrap;
}

.reset-filters {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #0880ff;
  font-family: 'SF Pro Display';
}

.icon-reset {
  font-size: 14px;
}

.d-flex-ai-cen {
  display: flex;
  align-items: center;
}

.d-flex_nowrap_space-between {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.d-flex_wrap_space-between {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-column {
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.product-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  min-width: 200px;
  width: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 50%;
}
.product-item h6 {
  font-weight: 500;
}
.heading-toggle {
  padding: 0;
}
.body-toggle {
  padding-left: 0;
  padding-bottom: 0;
}
.heading-toggle .heading-elements {
  right: 0;
  left: 40px;
}
.panel-c {
  margin-bottom: 20px;
  border-width: 0;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
  background-color: #fff;
  border: solid 1px rgba(192, 195, 208, 0.33);
  border-radius: 3px;
  box-shadow: none !important
}
.product-item:last-child {
  padding-bottom: 0;
}

.body-length {
  white-space: nowrap;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td-border {
  padding: 12px 20px;
  border-top: none !important;
}

.activity-td-border {
  padding: 12px 20px;
  border-top: 1px solid #ddd;
}

.pager {
  border-top: 1px solid #ddd;
}

.icon {
  font-size: 30px;
  margin-right: 10px;
}

.icon-sm {
  font-size: 16px;
  color: #3fa2ff;
}

.icons-bg {
  color: black;
}

.icons-bg:hover {
  color: #202f46;
}
.icons-bg:visited {
  color: black;
}

.microlink_card {
  border-color: transparent !important;
  max-width: 100% !important;
}
.table-brief {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  max-width: 260px;
  text-overflow: ellipsis;
}

.help-select-workroom {
  margin-top: -9px;
  height: 20px;
}
.login-checkboxes {
  width: 90%;
  margin: 0 auto 11%;
}
.justify {
  justify-content: center;
}
.mb-1 {
  margin-bottom: 1px;
}

.mb-50 {
  margin-bottom: 50px;
}
.skill-pr {
  padding-right: 16px !important;
}
.skill-column {
  display: flex;
  flex-direction: column;
}

.custom-hight {
  min-height: 62.2vh !important;
}

.not-logged-container {
  display: table;
  margin: 0 auto;
}
.m-0 {
  margin: 0;
}
.w-50 {
  width: 50%;
}
.dropdown-workroom {
  position: absolute;
  left: 22%;
  top: 13%;
}

.dropdown-article {
  position: absolute;
  left: 50%;
  top: 104%;
}

.w-auto {
  width: auto !important;
}

.brief-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #23a5f3;
  font-weight: 500;
  font-size: 14px;
}

.td-width {
  max-width: 100px !important;
}
@media (min-width: 769px) {
  .td-width {
    max-width: 50px;
  }
}
@media (min-width: 1130px) {
  .td-width {
    max-width: 200px;
  }
}
@media (min-width: 1300px) {
  .td-width {
    max-width: 350px;
  }
}
.td-width-contractor {
  max-width: 100px;
}
@media (min-width: 769px) {
  .td-width-contractor {
    max-width: 50px;
  }
}
@media (min-width: 1585px) {
  .td-width-contractor {
    max-width: 350px;
  }
}
.td-width-activity {
  max-width: 100px;
}
@media (min-width: 769px) {
  .td-width-activity {
    max-width: 100px;
  }
}
@media (min-width: 1130px) {
  .td-width-activity {
    max-width: 200px;
  }
}
.dropdown-max-height {
  max-height: 200px;
  overflow-y: scroll;
}
.w-300 {
  width: 300px;
}
.oval {
  width: 10px;
  height: 10px;
  background-color: #6abbfc;
  border-radius: 20px;
}
.radiobutton-margin {
  margin: 5px 0px 0px 8px;
}

.modal-closer {
  right: 2% !important;
  top: 20% !important;
}
.d-grid {
  display: inline-grid;
}
.default-cursor {
  cursor: default !important;
}
.height-12 {
  height: 12px;
}
.pr-200 {
  padding-right: 200px;
}
.border-radius-10 {
  border-radius: 10px;
}
.badge-col-green {
  background-color: #56d48f;
}
.badge-col-blue {
  background-color: #6abbfc;
}
.w-13 {
  width: 13%;
}
.brief-page-padding {
  padding-right: 40px;
  padding-left: 50px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.mt-30 {
  margin-top: 30px !important;
}
.d-inline-block {
  display: inline-block;
}
.oval-position {
  display: flex !important;
  align-items: center;
}
.d-block {
  display: inline-block;
}
.label-padding {
  padding: 2px 5px 2px 5px;
}
.p-absolute {
  position: absolute;
}
.pitch-contractor-name {
  top: 25%;
  left: 44%;
}
.pr-30 {
  padding-right: 30px;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-muted-important {
  color: #999999 !important;
}
.text-transform-none-important {
  text-transform: none !important;
}
.muted-img {
  filter: grayscale(100%);
}
.link-color {
  color: #1e88e5 !important;
}
.w-20p {
  width: 20%;
}
.w-30p {
  width: 30%;
}
@media (max-width: 768px) {
  .media-body {
    display: table-cell !important;
    width: 1000px !important;
  }
}
.m-0 {
  margin: 0px !important;
}
.center-align {
  align-self: center;
}
.start-align {
  align-self: start;
}
.badge-col-gray {
  background-color: #d8d8d8;
}
.border-radius-6 {
  border-radius: 6px;
}
.block-with-text {
  overflow: hidden;
  position: relative;
  max-height: 14em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
}
.block-with-text:before {
  content: '...';
  position: absolute;
  right: 0;
  bottom: 0;
}
.block-with-text:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
}
.plain-text {
  white-space: pre-line;
  text-align: justify;
  color: #65696D;
}
.float-none {
  float: none;
}
.m-10p-0 {
  margin: 10px 0;
}
.login-input {
  padding-left: 10px;
}
.login-input::placeholder {
  font-size: 16px;
}
.ui-pnotify.custom .ui-pnotify-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.in-progress {
  background: linear-gradient(268.56deg, #2CA694 14.77%, #35D1C2 71.39%);
}
.draft {
  background: linear-gradient(259.99deg, #CAAD47 -46.05%, rgba(255, 255, 255, 0) 88.49%), #E8760E;
}
.selecting {
  background: linear-gradient(90deg, #90CAF9 0%, #047EDF 100%, #047EDF 100%), #FDAD2A;
}
.completed {
  background: linear-gradient(268.68deg, #47ca4d 14.77%, #4aae4e 71.39%);
}
.selector-border {
  border: 1px solid #ddd !important;
}

.text-light-dark {
  color: #343a40;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.text-navy-blue {
  color: #5586d2;
}

.text-black-blue {
  color: #052947;
}

.btn-navy-blue {
  color: #fff !important;
  background-color: #2f48b6 !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px;
  font-family: 'SF Pro Display';
}

.btn-default-bg-white {
  background-color: #fff !important;
  color: #052947 !important;
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 14px;
  font-family: 'SF Pro Display';
}

td.text-black-blue,
td.center-flex {
  border-top: none !important;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.column {
  display: flex;
  flex-direction: column !important;
}

.h-100px {
  height: 100px;
}

.grey-collapse {
  color: #9c9fa6;
}
.pl-70 {
  padding-left: 70px !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.jc-end {
  display: flex;
  justify-content: flex-end !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.pl-40 {
  padding-left: 40px;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.p-10-20 {
  padding: 10px 20px;
}

.pb-0 {
  padding-bottom: 0px;
}

.mr-30 {
  margin-right: 30px!important;
}
.b-none {
  border: none !important;
}

.panel {
  padding: 30px 37px;
  border-radius: 5px; 
}

#root {
  min-height: 100vh;
}

.tox .tox-textfield, .tox .tox-toolbar-textfield, .tox .tox-textarea {
  border: 1px solid #BCBCBC !important;
  font-family: 'SF Pro Display';
  font-style: normal;
  
  font-size: 15.12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #052947 !important;
}

.tox .tox-textarea:focus {
  border: 1px solid #BCBCBC !important;
}

.tox .tox-textarea::placeholder {
  color: #BCBCBC !important;
  font-weight: 600;
}

.tox {
  margin: 8px !important;
}

.tox .tox-comment {
  background: #F7F7FA !important; 
  border: none !important;
  padding: 12px !important;
}

.tox .tox-comment__reply {
  justify-content: space-between !important;
  margin-bottom: 16px;
}

.tox .tox-user__name {
  font-family: 'SF Pro Display';
  font-size: 17.1195px !important;
  /* line-height: 17px; */
  display: flex;
  align-items: center;
  color: #052947 !important;
  text-transform: capitalize !important;
  flex-wrap: nowrap !important;
}


.tox .tox-button {
  /* background-color: #2F48B6 !important; */
  width: 89px;
  height: 34.1px;
  border-radius: 3px !important;
}

.tox .tox-button--naked.tox-button--icon {
  width: 32px !important;
}

.tox-user__avatar {
  width: 45px;
  height: 45px;
}

.tox .tox-comment__date {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 600;
  font-size: 10.2717px !important;
  line-height: 10px;
  display: flex;
  align-items: center;
  color: #9C9FA6 !important;
}

.tox .tox-comment__body  {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: normal;
  font-size: 13px !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #7A7A7A !important;
}

.tox .tox-comment {
  box-shadow: none !important;
  margin-bottom: 8px !important;
}

.mce-edit-aria-container>.mce-container-body .mce-sidebar-panel {
  min-width: 300px !important;
  max-width: 300px !important;
}

.tox-comment__scroll {
  height: 75% !important;
  overflow-y: auto !important;
}

.tox .tox-button { 
  background: #2F48B6 !important; 
  font-family: 'SF Pro Display' !important;
  font-style: normal;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 14px;
  color: #FFFFFF;
  margin-bottom: 3px !important;
}


.tox-button.tox-button--secondary {
  background: #FBFBFB !important;
  border: 1px solid #AFBAC4 !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  font-family: 'SF Pro Display' !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px;
  color: #889198 !important;
}

.tox-button.tox-button--icon {
  background: transparent !important;
  border: none !important;
  color: #9C9FA6 !important;
}
.mce-panel {
  box-shadow: none !important;
}

.mce-btn .mce-txt {
  color: #595959;
  font-weight: 600;
  font-family: 'SF Pro Display' !important;
}

.mce-top-part::before {
  box-shadow: none !important;
}

.assigned-user-name {
  text-decoration-line: underline;
  color: #343A40;
}

.assigned-user-name:hover {
  color :#166dba
}

.collapsed-button-title {
  color: #31ABF4 !important;
  font-weight: 600 !important;
}

.mr-0 {
  margin-right: 0px !important;
}
.break-word {
  word-wrap: break-word;
}

.navigation li div:hover,
.navigation li div:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.navigation li div {
  cursor: pointer;
}

.ReactModal__Overlay--after-open {
  z-index: 1080 !important;
}
