.ui-icon-messages-has-messages > path {
  fill: #4DA6EC;
}

.ui-icon-messages .message-navigation-icon-circle {
  display: none;
}

.ui-icon-messages-has-messages .message-navigation-icon-circle {
  display: block;
}