.dashboard-briefs-post-job-button {
  text-transform: uppercase;
  background: #1c3296;
  border-radius: 33px;
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 186px;
  height: 35px;
  position: relative;
}

.dashboard-briefs-post-job-button svg {
  margin-left: 20px;
  position: absolute;
  right: 17px;
}

.dashboard-briefs-post-job-button svg path {
  fill: #ffffff;
}
