@media screen and (min-width: 2500px) and (max-width: 3000px) {
  .span__width {
    width: 40%;
  }
}
@media screen and (max-width: 2500px) {
  .span__width {
    width: 38%;
  }
}
@media screen and (max-width: 2200px) {
  .span__width {
    width: 33%;
  }
}

@media screen and (max-width: 1600px) {
  .form__brief-content {
    width: 70%;
  }
  .form__brief-flex {
    width: 20%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1500px) {
  .content-manager__item .btn {
    padding: 9px 4px;
    font-size: 11px;
  }
}
@media screen and (max-width: 1400px) {
  .form__brief-content {
    width: 65%;
  }
  .form__brief-flex {
    width: 25%;
  }
  .span__width {
    width: 20%;
  }
}
@media screen and (max-width: 1380px) {
  .tab__brief-btn {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
  }
  .tab__brief-btn button:last-child {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1345px) {
  .datatable-scroll-brief {
    width: 100%;
    overflow-x: scroll;
    min-height: 250px
  }
  .datatable-scroll-brief th, .datatable-scroll-brief td {
    white-space: nowrap;
  }
}
@media screen and (max-width: 1100px) {
  .form__brief-txt {
    text-align: justify;
  }
  .container-custom {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 1199px) {
  .signin__form-entry input {
    width: 90%;
  }
  .signin__box {
    width: 40%;
  }
  .content-manager__message textarea, .content-manager__message button {
    width: 100%;
  }
  .content-manager__proceed {
    width: 100%;
    margin-left: 0;
  }
  .content-manager__proceed .tab-content > .has-padding {
    padding-left: 0;
  }
  .content-manager__box:last-child {
    margin-bottom: 20px;
  }
  .content-manager__rating {
    width: 100%;
    margin-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .signin {
    min-height: auto;
  }
  .footer__logo {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  .footer__item {
    margin-bottom: 40px;
    text-align: center;
  }
  .footer__social {
    float: none;
  }
  .footer__circs-txt {
    margin-bottom: 30px;
    text-align: center;
  }
  .footer {
    /* This breaks down the footer's work */
     /* position: static;  */
  }
  .header {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    height: 100%;
  }
  .header__nav {
    display: none;
  }
  .header__menu-mobile {
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
  }
  .home__create-img {
    display: none;
  }
  .home__content-box p {
    opacity: 1;
  }
  .messages__input textarea {
    width: 100%;
  }
  .messages__input button {
    width: 100%;
  }
}
@media screen and (max-width: 927px) {
  .form__group-one {
    width: 80%;
    margin: 0 auto;
  }
  .form__group-two {
    width: 100%;
    text-align: center;
  }
  .brief-main-form .uploader {
    padding-bottom: 35px;
  }
}
@media screen and (max-width: 900px) {
  .form__brief-price {
    font-size: 22px;
  }
  .form__brief-content {
    width: 60%;
  }
  .form__brief-flex {
    width: 30%;
  }
  .form__brief-click {
    width: 80%;
  }
  .brief-main-form .label-help {
    padding: 2px 8px;
  }
  .my-content__btn button {
    width: 40%;
  }
  .form__brief-btn button, .form__brief-btn a {
    width: 48% !important;
  }
  .form__brief-btn {
    width: 60%;
  }
  .nav-tabs-vertical > .nav-tabs {
    width: 180px;
  }
}
@media screen and (max-width: 880px) {
  .dataTables_filter {
    width: 100%;
  }
  .dataTables_length {
    display: inline-block;
    margin: 0 0 20px 0px;
    float: left;
  }
}
@media screen and (max-width: 800px) {
  .form__brief-label span {
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .mq .tab-content > .has-padding {
    padding-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .header__container {
    width: 100%;
  }
  .home__create-signup {
    text-align: center;
  }
  .wizard > .steps > ul > .three {
    width: 100%;
  }
  .mb-mq-20 {
    margin-bottom: 20px;
  }
  .content-manager__box {
    min-width: 900px;
  }
  .scroll {
    overflow-x: scroll;
  }
  .content-manager__proceed .tab-content .tab-pane button {
    margin-bottom: 15px;
  }
  .nav-tabs-vertical > .nav-tabs {
    width: auto;
  }
}
@media screen and (max-width: 759px) {
  .home__form-item {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0 !important;
  }
}
@media screen and (max-width: 660px) {
  .signin__box {
    width: 80%;
  }
}
@media screen and (max-width: 570px) {
  .home__create {
    padding-top: 50px;
  } 
  .home__create-box {
    margin-top: 50px;
    width: 75%;
  }
  .home__create-box p {
    text-align: center;
  }
  .home__create-btn {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column;
    -ms-align-items: center;
    align-items: center;
    margin-bottom: 50px;
  }
  .home__create-btn button {
    margin-bottom: 20px;
  }
  .form__brief-btn button {
    padding: 8px;
    font-size: 12px;
  }
  .form__brief-question {
    font-size: 18px;
  }
  .form__brief-head label {
    font-size: 16px;
  }
  .modal__download, .modal__flag {
    width: 100%;
  }
  .modal__download {
    margin-bottom: 10px;
  }
  .modal-content .modal-footer .modal__flag {
    margin-left: 0;
  }
}
@media screen and (max-width: 500px) {
  .form__brief-label span {
    font-size: 12px;
  }
  .summary-wrapper h4 {
    font-size: 24px;
  }
  .form__brief-summary_item span {
    font-size: 16px;
  }
  .form__brief-content_box {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form__brief-content {
    margin-top: 20px;
    width: 100%;
    padding-left: 0;
  }
  .form__brief-flex {
    width: 100%;
  }
  .form__brief-price {
    width: 100%;
    text-align: center;
  }
  .form__brief-caption {
    font-size: 16px;
    text-align: center;
  }
  .form__brief-txt {
    font-size: 15px;
  }
  .form__brief-summary_item span {
    font-size: 15px;
  }
  .form__brief-btn {
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    -o-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .form__brief-btn button, .form__brief-btn a {
    width: 100% !important;
    margin-bottom: 15px;
  }
  .form__brief-btn button:last-child, .form__brief-btn a:last-child {
    width: 100% !important;
    padding: 7px;
  }
  .content-manager__rating {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 450px) {
  .signin__box {
    width: 100%;
  }
  .home__create h1 {
    width: 100%;
    font-size: 48px;
  }
  .home__content-box h2 {
    width: 100%;
  }
  .home__content-box {
    margin-bottom: 60px;
  }
  .home__info p {
    width: 100%;
  }
  .footer__circs-txt span:last-child {
    padding-right: 0;
  }
  .summary-wrapper {
    padding: 14px;
  }
  .brief-main-form .uploader {
    padding: 25px;
  }
  .brief-main-form .title {
    font-size: 18px;
  }
}
@media screen and (max-width: 400px) {
  .form__brief-summary_item span {
    font-size: 12px;
  }
  .form__brief-head label {
    font-size: 14px;
  }
  .form__brief-head span {
    width: 20px;
    height: 20px;
    font-size: 17px;
  }
}
@media screen and (max-width: 320px) {
  .header__mobile-visible {
    height: 84vh;
  }
  .form__brief-summary_item:last-child span {
    font-size: 20px;
  }
  .content-manager__view button {
    font-size: 12px;
  }
}