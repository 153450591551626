.select-container {
  position: relative !important;
}

.select-container_wrapper {
  position: relative;
}

.select-list-container_wrapper {
  width: inherit;
}

.select-list-container {
  position: absolute;
  top: 114%;
}

.select-results__none {
  padding-left: 16px;
}

.select-load-more {
  color: #2196f3;
  padding: 0 16px;
  cursor: pointer;
}

.select-load-more:hover {
  color: #0a6ebd;
}

.select-left-container {
  display: flex;
  align-items: center;
}

.select-arrow {
  transition: transform .5s;
  color: #052947;
}

.arrow-open {
  transform: rotate(-180deg);
}

.select2-selection {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select2-selection__clear {
  margin: 0 !important;
}

.select2-selection > .select2-selection__rendered {
  overflow: visible;
}

.select2-results > .select2-results__options {
  overflow: auto;
}

.select2-container--open {
  padding: 0;
  margin: 0;
}

.select2-dropdown {
  top: 10px !important;
}

.with-label .select2 {
  display: flex;
}

.with-label .select2-selection {
  border-radius: 0 33px 33px 0;
}

.select2-label {
  background: #F0F0F0;
  border: 1px solid #E3E3E3;
  border-right: none;
  padding: 12px 12px 12px 18px;
  border-radius: 33px 0 0 33px;
  height: 38px;
  width: 100%;
  display: flex;
  align-items: center;
  color: #8D8D8D;
  font-family: 'SF Pro Display';
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
}

.select-container > div {
  position: relative;
}

.select-arrow-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  right: 0px;
  top: 0;
  height: 100%;
}

.multiple-item {
  display: flex;
  flex-wrap: nowrap;
  background: #F7F7FA;
  border: 1px solid #DADADA;
  border-radius: 31px;
  padding: 3px 10px;
  margin-bottom: 3px;
  margin-top: 3px;
}

.multiple-text-decoration {
  font-weight: 600 !important;
  color: #969DA5 !important;
  padding-right: 10px !important;
}

.multiple-item:not(:last-child) {
  margin-right: 5px;
}

