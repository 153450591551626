/* Common css */
.m-auto {
  margin: 0 auto;
}
.mb-0 {
  margin-bottom: 0;
}
.mt-0 {
  margin-top: 0;
}
.mt-20 {
  margin-top: 20px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb_70 {
  margin-bottom: 70px;
}
.mt-12 {
  margin-top: 12px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-25 {
  margin-left: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.container-custom {
  margin-left: auto;
  margin-right: auto;
  padding-left: 100px;
  padding-right: 100px;
}

.fd-row {
  -webkit-flex-direction: row !important;
  -moz-flex-direction: row !important;
  -ms-flex-direction: row !important;
  -o-flex-direction: row !important;
  flex-direction: row !important;
}
.flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.jcc {
  justify-content: center;
}
.jcfe {
  justify-content: flex-end;
}
.jcfs {
  justify-content: flex-start;
}
.jcsb {
  justify-content: space-between;
}
.aifs {
  -ms-align-items: flex-start !important;
  align-items: flex-start !important;
}
.fww {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}
.text-underline {
  text-decoration: underline;
}
.brad-0 {
  border-radius: 0;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
/* End Common css */


.login__wrapper {
  height: 100%;
  min-height: 100vh;
}
.signin {
  height: 100%;
  background: #f2f4f5;
}
.signin__wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 70vh;
  width: 100%;
}
.signin__box {
  margin: 0 auto;
  width: 28%;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  max-width: 350px;
}
.signin__logo {
  margin-top: 7%;
  margin-bottom: 15%
}
.signin__logo img {
  width: 120px;
}
.signin__head h3 {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: .6px;
  color: #6abbfc;
  margin-top: 6%;
  margin-bottom: 6%;
}
.signin__form {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.signin__form-entry {
  padding: 0 3%;
  margin-bottom: 2%
}
.signin__form-entry input {
  width: 90%;
  margin-bottom: 2%;
  border: 1px solid #d3d3d3;
  font-size: 18px;
  border-radius: 3px;
}
.signin__form-control {
  margin-top: 2%;
  width: 50%;
}
.signin__form-control label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.signin__form-control span {
  display: inline-block;
  font-size: 14px;
}
.signin__form-control input {
  margin: 0;
  margin-left: -12%;
  width: 14%;
  height: 16px;
}
.signin__form-forgot {
  margin-bottom: 11%;
}
.signin__form-forgot span a {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: .6px;
  color: #000000;
}
.signin__btn {
  margin-bottom: 10%;
  padding: 10px;
  width: 55%;
  font-size: 13px;
  text-transform: uppercase;
  color: #ffffff;
  background: #6abbfc;
  border: none;
  -webkit-box-shadow: 1px 0px 13px 1px rgba(0,0,0,0.11);
  -moz-box-shadow: 1px 0px 13px 1px rgba(0,0,0,0.11);
  box-shadow: 1px 0px 13px 1px rgba(0,0,0,0.11);
}
.signin__account {
  margin-bottom: 10%;
  font-size: 14px;
}
.signin__account a {
  font-weight: 900;
  color: #000000;
}
/* End Login */

/* Footer */
.footer__wrapper {
  font-family: 'Roboto', sans-serif;
  position: static;
  bottom: 0;
  width: 100%;
  height: 30%;
  background: #6abbfc;
}
.footer__box {
  padding-top: 2%;
}
.footer__logo img {
  width: 120px;
}
.footer__item {
  padding-left: 0;
}
.footer__item li:first-child {
  text-transform: uppercase;
}
.footer__item li {
  list-style-type: none;
}
.footer__item li a {
  font-size: 14px;
  text-decoration: none;
  color: #fff;
}
.footer__item li a span {
  color: #f2f4f5;
}
.footer__circs {
  margin-top: 25px;
}
.footer__circs-txt {
  margin-bottom: 40px;
}
.footer__circs-txt span {
  padding-right: 20px;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 900;
}
.footer__circs-txt span a {
  color: #ffffff;
  letter-spacing: .6px;
}
.footer__item i {
  padding-right: 10px;
  font-size: 32px;
  color: #ffffff;
}
.footer__social {
  float: right;
}
/* End Footer */

/* Header */
.header {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 100px;
}
.header__logo {
  padding: 15px 0;
}
.header__nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
}
.header__list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin: 0;
}
.header__list li {
  padding: 15px;
  list-style-type: none;
}
.header__list li a {
  text-transform: uppercase;
  color: rgba(0,0,0,.8);
}
.header__list li .header__btn {
  padding: 15px 30px;
  font-weight: bold;
  text-transform: uppercase;
  background: #070a38;
  color: #ffffff;
  border: 1px solid #070a38;
}
.header__menu-mobile {
  display: none;
  width: 30px;
  height: 30px;
  float: right;
  margin-top: 25px;
}
.header__menu-box span {
  display: block;
  width: 100%;
  height: 3px;
  background: #888;
  margin-bottom: 5px;
  border-radius: 6px;
}
.header__menu-box {
  width: 100%;
  cursor: pointer;
}
.header__menu-box span:last-child {
  margin-bottom: 0;
}
.header__mobile-hidden {
  display: none;
}
.header__mobile-visible {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.header__mobile-list {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  padding: 0;
}
.header__mobile-list li {
  list-style-type: none;
}
.header__mobile-list li a {
  font-size: 16px;
}
/* End Header */

/* Home */
.home {
  height: 100%;
  background: #ffffff;
}
.home__create {
  font-family: 'Roboto', sans-serif;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  padding-top: 100px;
  background: url(../../images/backgrounds/background-home.png) no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
}
.home__create h1 {
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 200;
  width: 400px;
  text-align: center;
  line-height: 1;
  opacity: .8;
}
.home__create-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  margin-top: 120px;
  width: 524px;
}
.home__create-box p {
  font-size: 21px;
  letter-spacing: .6px;
  margin-bottom: 30px;
}
.home__create-btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 120px;
  width: 100%;
}
.home__create-btn button {
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: transparent;
  color: #070a38;
  border: 1px solid #070a38;
  width: 255px;
  transition: all .3s;
}
.home__create-btn button:hover {
  background: #070a38;
  color: #ffffff;
}
.home__create-signup {
  margin-bottom: 40px;
  color: #070a38;
  font-size: 21px;
}
.home__create-signup a {
  font-weight: bold;
  color: #070a38;
  text-decoration: none;
}
.home__create-img {
  position: absolute;
  width: 250px;
  top: 390px;
}
.home__content {
  padding-top: 120px;
  font-family: 'Roboto', sans-serif;
  background: url(../../images/backgrounds/background-note.png) no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}
.home__content-box {
  margin-bottom: 100px;
}
.home__content-box h2 {
  width: 320px;
  font-size: 50px;
  font-weight: 200;
  line-height: 1.1;
  text-transform: uppercase;
}
.home__content-box h2 span {
  font-weight: 400;
  color: #77c7fb;
}
.home__content-box p {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size: 20px;
  opacity: .9;
}
.home__content-check {
  margin-top: 35px;
}
.home__content-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
}
.home__content-item img {
  width: 30px;
  margin-right: 10px;
}
.home__content-item span {
  display: inline-block;
  font-size: 20px
}
.home__info {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;

}
.home__info h3 {
  margin-bottom: 30px;
  width: 230px;
  text-align: center;
  font-size: 30px;
  text-transform: uppercase;
}
.home__info p {
  font-size: 20px;
  width: 320px;
  text-align: center;
}
.home__works__btn {
  margin-top: 60px;
  margin-bottom: 100px;
  padding: 15px 55px;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  background: #070a38;
  color: #fff;
  border: 1px solid #070a38;
}
/* End Home */

/* Form brief three */
.form__brief-three {
  font-family: 'Roboto', sans-serif;
  background: #ffffff;
}
.form__brief-question span {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  width: 28px;
  height: 32px;
  background: #77c7fb;
  color: #ffffff;
}
.form__brief-question p {
  margin-left: 10px;
  color: #77c7fb;
}
.form__brief-question {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  font-size: 22px;
  font-weight: 600;
}
.form__brief-head {

}
.form__brief-head label {
  text-transform: uppercase;
}
.form__brief-head span {
  font-family: 'Roboto', sans-serif;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  margin-left: 10px;
  width: 26px;
  height: 26px;
  font-size: 22px;
  border-radius: 50%;
  background: #070a38;
  color: #ffffff;
  font-weight: bold;
}
.form__brief-checkbox  {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  margin-right: 15px;
}
.form__brief-checkbox input {
  margin-top: 0;
  width: 26px;
  height: 26px;
}
.form__brief-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: flex-start;
  align-items: flex-start;
  width: 15%;
}
.form__label-box {
  width: 80%;
}
.form__brief-label {
  margin-bottom: 5px;
  text-transform: uppercase;
  cursor: pointer;
}
.label-one {
  background: #e63e6a;
}
.label-two {
  background: #6abbfc;
}
.label-three {
  background: #fbc469;
}
.form__brief-label span {
  padding: 8px;
  font-size: 13px;
}
.form__brief-caption {
  margin-top: 0;
}
.form__brief-content {
  padding-left: 18px;
  width: 80%;
}
.form__brief-content_box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid #949899;
}
.form__brief-price {
  font-size: 20px;
}
.summary-wrapper {
  padding: 50px;
  background: #f2f4f5;
}
.summary-wrapper h4 {
  margin-bottom: 25px;
  font-size: 28px;
  font-weight: 700;
  color: #6abbfc;
}
.form__brief-summary_item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.form__brief-btn {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: right;
  margin: 50px auto;
}
.form__brief-btn button, .form__brief-btn a {
  width: 40%;
  margin-left: 20px;
}
.form__brief-click {
  display: block;
  margin: 0 auto;
  color: #949899;
  margin: 0px -12% 30px -12%;
}
.form__brief-click a {
  color: #949899;
  text-decoration: underline;
}
/* Form brief three */

/* Form brief one and two */

.brief-progress {
  margin-top: 50px;
  margin-bottom: 50px;
}

.brief-progress .panel {
  padding: 50px 30px;
  box-shadow: none;
  background-color: #f2f4f5;
}

.progress {
  background-color: #fff;
}

.progress .progress-bar{
  width: 50%;
  background-color: #7bb8f7;
}

.brief-main-form .panel {
  box-shadow: none;
}

.brief-main-form .form-group {
  margin-bottom: 20px;
}

.brief-main-form .title {
  color: #7bb8f7;
  font-weight: 600;
  font-size: 22px;
}

.check__label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.brief-main-form .form-group .form-control:focus {
  box-shadow: none;
}

.brief-main-form .form-group .select {
  width: 50%;
  display: flex;
  background: #fff;
  border: 1px solid #949798;
  font-size: 18px;
  padding: 8px;
}

.brief-main-form .form-group .select.w-100 {
  width: 100%;
}

.brief-main-form .bottom-buttons {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.brief-main-form .bottom-buttons {
  margin-top: 70px;
}

.brief-main-form .btn-submit {
  min-width: 40%;
  margin-right: 30px;
  margin-bottom:30px;
  padding: 15px 55px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  background: #7bb8f7;
  color: #fff;
  border: 1px solid #7bb8f7;
}

.brief-main-form .btn-next {
  min-width: 40%;
  margin-bottom: 30px;
  padding: 15px 55px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  background: #070a38;
  color: #fff;
  border: 1px solid #070a38;
  text-align: center;
  cursor: pointer;
}

.brief-main-form .panel-grey {
  background-color: #f2f4f5;
  padding: 50px;
  padding-bottom: 0px;
}

.brief-main-form .mb-50 {
  margin-bottom: 50px;
}

.slider__wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
}

.brief-main-form .uploader .action {
  width: 100%;
  background-color: #070a38;
  color: #fff;
  text-transform: uppercase;
  font-size: 20px;
  border-radius: 0;
  padding: 15px;
}

.brief-main-form .uploader {
  padding-bottom: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.brief-main-form .uploader .filename {
  font-size: 18px;
  white-space: normal;
}

.brief-main-form .label-help {
  background-color: #0a1339;
  padding: 5px 11px;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
}

.brief-progress-bar {
  margin: 30px 0;
  padding: 20px 0px;
  background-color: #f2f4f5;
}

.navigation > li > .dashboard__box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  padding: 12px 20px;
  min-height: 44px;
  font-weight: 500;
}
.uploader input[type=file] {
  height: 100%;
}

.navigation > li > .dashboard__box i {
  margin-right: 15px;
}

.d-flex {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.form__group-one {
  width: 40%;
  min-width: 203px;
}

.form__group-two {
  width: 60%;
}

label .icon-question4 {
  margin-left: 10px;
}
.label__pad {
  padding-bottom: 15px;
}
.label__pad label {
  margin: 0;
}
/* Form brief one and two */

/* Custom size page */
.custom-size-page {
  background-color: #fff;
}

.custom-size-page .custom-size-main {
  margin-top: 50px;
}

.custom-size-page .custom-size-main ul {
  padding-left: 0;
  list-style-type: none;
}

.custom-size-page .custom-size-main ul li {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.custom-size-page .custom-size-main .tabbable {
  margin-bottom: 50px;
}
.brief-page__footer button {
  margin-bottom: 15px;
}

/* Custom size page */
/* My content */
.my-content {
  background: #ffffff;
}
.input-group-btn:last-child {
  padding-left: 16px;
}
.border__grey {
  margin-left: 10px;
  border: 1px solid #ddd;
}
.select__group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.table-borderless thead tr th {
  text-transform: uppercase;
}
.my-content__btn {
  position: relative;
}
.my-content__btn button {
  margin: 0 auto;
  width: 20%;
}
.my-content__border {
  position: absolute;
  bottom: 50%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  -o-transform: translate(0, 50%);
  transform: translate(0, 50%);
  width: 100%;
  border: 1px solid #ddd; 
}
.my-content-add p {
  margin-top: 6px;
}

.brief-page {
  background-color: #fff;
}

.brief-page__top__rigth-text__watching i {
  padding-right: 10px;
}

.brief-page__table__avatar {
  display: flex;
  flex-direction: column;
  max-width: 130px;
}
.my-content__tbl {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  width: 130px;
}
.media-body a {
  text-transform: uppercase;
  color: #333333;
}
.messages__input {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: flex-end;
  align-items: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;
}
.messages__input textarea {
  width: 100%;
  min-height: 100px;
  resize: none;
  margin-bottom: 10px;
  padding: 15px 20px;
}
.messages__input button {
  width: 100%;
  text-transform: uppercase;
}
.tab-content > .has-padding {
  padding-top: 0;
  padding-right: 0;
}
.media-body .display-block {
  text-align: justify;
}
/* End My content */

/* Content Manager */
.content-manager, .content-view {
  background: #fff;
}
.content-manager__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%; 
}
.wizard > .steps > ul > li.disable > a {
  color: #333333;
}
.content-manager__item p, .content-manager__item h6 {
  text-align: justify;
}
.content-manager__span {
  text-align: center;
}
.content-manager__proceed {
  width: 86%;
  margin-left: 14%;
}
.content-manager__rating {
  margin-top: 50px;
  margin-left: 6%;
  width: 40%;
}
.content-manager__wrap {
  min-width: 450px;
}
.content-manager__rating-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
}
.content-manager__rating-item span {
  display: inline-block;
  min-width: 60px;
}
/* End Content Manager */

/* Content View */
.content-view__scroll {
  height: 750px;
}
/* End Content View */

/* Home Dashboard */
.home__form-band {
  border-bottom: 1px solid #ddd;
}
.home__form-box {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  -ms-align-items: center;
  align-items: center;
}
.home__form-txt {
  display: block;
  padding: 45px 60px;
  min-width: 170px;
}
.home__form-blocks {
  width: 100%;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}
.home__form-inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.home__form-inner img {
  width: 130px;
}
.home__form-wrapper {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}
.flex:last-child .home__form-wrapper {
  border-bottom: 0;
}
.home__form-item {
  width: 220px;
}
.home__form-wrapper {
  min-width: 750px;
}
/* End Home Dashboard */

/* Modal Block */
.modal__download, .modal__flag {
  width: 30%;
}
.modal-footer .bg-teal-400:hover {
  background: #26a684;
}
.modal-body textarea {
  max-width: 560px;
}
/* End Modal Block */

.message__box {
  padding: 15px 20px;
  width: 100%;
  border: 1px solid #ddd;
}
.media-body a {
  font-weight: 500
}
.content-manager__icon {
  display: inline-block;
  width: 75px;
}
.transparency {
  opacity: 0.4;
  background: #f5f6f7;
}

.main-headline {
  font-family: 'SF Pro Display';
  font-size: 30px;
  font-weight: 600;
  color: #052947;
}