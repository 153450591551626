.custom-button {
    display: inline-block;
    font-weight: 500;
    color: #fff;
    background-color: #2196f3;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .5rem 1rem;
    line-height: 1.5385;
    border-radius: .1875rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    text-transform: uppercase;
    width: 100%;
}

.custom-button[disabled] {
  opacity: 0.5;
}

.custom-button:hover,
.custom-button:focus,
.custom-button:active {
  box-shadow: 0 0 0 62.5rem rgba(0,0,0,.075) inset;
}