.ui-icon-notifications {
  margin: auto;
  height: 25px;
}

.ui-icon-notifications > rect {
  stroke: #9C9FA6;
}

.ui-icon-notifications > path {
  fill: #9C9FA6;
}

.ui-icon-notifications-not-has-messages .notifications-navigation-icon-circle,
.ui-icon-notifications-not-has-messages .st1 {
  display: none;
}

.ui-icon-notifications-wrapper {
  width: 27px;
  height: 27px;
}

.ui-icon-notifications .notifications-navigation-icon-circle {
  display: none;
}

.ui-icon-notifications-has-new-notification .notifications-navigation-icon-circle {
  display: block;
}

.ui-icon-notifications-has-new-notification > rect {
  stroke: #4DA6EC;
}

.ui-icon-notifications-has-new-notification > path {
  fill: #4DA6EC;
}


