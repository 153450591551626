.mobile-view {
    background-color: #2F48B6;
    height: 100vh;
}

.mobile-view-circle {
    background-color:#fff;
    border: 6px solid rgb(130, 194, 246, 0.9);  
    height:120px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:120px;
    padding-top: 24px;
}

.mobile-view-logo {
    margin: 100px 0 40px;
}

.mobile-view-circle img {
    height: 60px;
    width: 60px;
}

.mobile-view-info-container {
    background: #fff;
    /* background: linear-gradient(180.49deg, #82C2F6 0.82%, #1285E1 100.42%); */
    margin: 10px 40px 0;
    padding: 20px;
    color: #052947;

    border: 6px solid rgb(52, 80, 203, 0.9);
    border-radius: 4px;
}

.mobile-view-info-image {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.mobile-view-info-content {
    font-size: 14px;
    font-weight: 500;
}

.mobile-view-container {
    text-align: center;
}